<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh tài khoản bị khóa</h6>
        </template>
        <b-row>
          <b-col class="col-md-4">
            <b-form-group label="Số điện thoại">
              <b-form-input
                  v-model="filter.phone"
                  placeholder="Số điện thoại"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col mt-5 pt-4">
            <b-button class="mr-2" variant="outline-info" @click="search">Tìm kiếm</b-button>
          </b-col>
        </b-row>

        <hr />
        <div v-if="paginate.total > 0"><span>Tổng số: </span><strong>{{ numberFormat(paginate.total) }}</strong></div>
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(block_type)="data">
            {{ data.item.block_type === "block_account" ? "Khóa tài khoản" : data.item.block_type }}
          </template>
          <template #cell(reason)="data">
            {{ data.item.message_log ? data.item.message_log.description : "" }}
          </template>
          <template #cell(created_by)="data">
            {{ data.item.message_log ? data.item.message_log.created_by : "" }}
          </template>
          <template #cell(options)="data" class="text-right">
            <b-button-group size="sm">
              <b-button class="btn btn-sm btn-outline-success" @click="showModal(data.item.phone, data.item.block_type)">Mở khóa</b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>

    <b-modal id="my-modal" hide-footer ref="myModal" :title="`Mở khóa user: ${this.unlockData.phone}`">
      <b-form class="col-12">
        <b-form-group label="Lý do (*)">
          <b-form-textarea
              v-model="unlockData.description"
              placeholder="Nhập lý do"
              rows="5"
          ></b-form-textarea>
        </b-form-group>
        <b-row style="float: right">
          <b-button type="submit" variant="primary" v-on:click="unlockUser()"
          >Mở khóa
          </b-button>
        </b-row>
      </b-form>
    </b-modal>

  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        title: null,
        description: null,
        status: null,
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
      },
      fields: [
        { phone: "Số điện thoại" },
        { email: "Email" },
        { name: "Họ tên" },
        { block_type: "Loại" },
        { reason: "Lý do" },
        { created_by: "Người khóa" },
        { options: "Hành động" },
      ],
      unlockData: {
        phone: null,
        block_type: null,
        description: null
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách hành động", route: "tools/users/block" },
    ]);
  },
  methods: {
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    search() {
      this.filter.page = 1;
      router.push({ path: "/tools/users/block", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.usersLock(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data.data;
          this.paginate.total = response.data.data.data.total;
          this.paginate.totalPage = response.data.data.data.last_page;
          this.paginate.currentPage = response.data.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    showModal(phone, type) {
      this.unlockData.phone = phone;
      this.unlockData.block_type = type;

      this.$refs["myModal"].show();
    },
    unlockUser() {
      let conf = confirm("Bạn có chắc chắn mở khóa tài khoản này không?");

      if (conf === false) {
        return true;
      }

      this.$bus.$emit("show-loading", true);
      let params = this.unlockData;

      if (!params.description) {
        this.notifyAlert("warn", "Lý do không được để trống");
        this.$bus.$emit("show-loading", false);
        return false;
      }

      params = this.convert(params);
      this.$refs["myModal"].hide();

      CmsRepository.unLockUser(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.getList();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.query = this.$route.query;
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
